import {
    showOnScroll
} from './function.js';

var t1 = 0;
const show_on_scroll = document.querySelectorAll('.show_on_scroll');
const toTop_elements = document.querySelectorAll('.toTop');
var timeout = 100;

window.addEventListener("scroll", function() {
    showOnScroll(show_on_scroll);
}, false);

toTop_elements.forEach(function(toTop_element) {
    toTop_element.addEventListener("click", function(event) {
        totop();
    }, false);

});

function totop() {
    var y1 = window.scrollY;
    y1 = y1 - 2000;
    window.scroll({
        top: 0,
        behavior: "smooth"
    })
    if (y1 > 0) {
        t1 = setTimeout(totop(), timeout);
    } else {
        clearTimeout(t1);
    }
}