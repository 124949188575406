window.getOffset = function(el) {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
    }
}

var lastScrollTop = 0;

export function showOnScroll(el) {
    var st = window.pageYOffset || document.documentElement.scrollTop;

    if (st > lastScrollTop || st == 0) {
        if (!Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            el.style.opacity = '0';
            el.style.visibility = 'hidden';
            element.style.webkitTransition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
            element.style.transition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
        } else {
            el.forEach(function(element) {
                element.style.opacity = '0';
                element.style.visibility = 'hidden';
                element.style.webkitTransition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
                element.style.transition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
            });
        }
    } else {
        if (!Object.prototype.isPrototypeOf.call(NodeList.prototype, el)) {
            el.style.opacity = '1';
            el.style.visibility = 'visible';
            element.style.webkitTransition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
            element.style.transition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
        } else {
            el.forEach(function(element) {
                element.style.opacity = '1';
                element.style.visibility = 'visible';
                element.style.webkitTransition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
                element.style.transition = 'opacity .2s cubic-bezier(0.4, 0, 0.2, 1)';
            });
        }
    }
    lastScrollTop = st <= 0 ? 0 : st;
}